import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Page1 = React.lazy(() => import('./Page1'));
const Page2 = React.lazy(() => import('./Page2'));
const Plan = React.lazy(() => import('./Plans'));
const AddPlan = React.lazy(() => import('./Plans/AddPlan'));
const AddUser = React.lazy(() => import('./Page1/AddUser'));
const Addbrand = React.lazy(() => import('./Page2/AddBrands'));
const Category = React.lazy(() => import('./Category'));
const AddCategory = React.lazy(() => import('./Category/AddCategory'));
const SubCategory = React.lazy(() => import('./Sub Category'));
const AddSubCategory = React.lazy(() =>
  import('./Sub Category/Add SubCategory'),
);

export const samplePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/page-1',
    element: <Page1 />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/adduser',
    element: <AddUser />,
  },

  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/addbrand',
    element: <Addbrand />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Category',
    element: <Category />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/AddCategory',
    element: <AddCategory />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/SubCategory',
    element: <SubCategory />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/AddSubCategory',
    element: <AddSubCategory />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/addplan',
    element: <AddPlan />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/page-2',
    element: <Page2 />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/plan',
    element: <Plan />,
  },
];
