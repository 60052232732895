import {
  GET_BRANDS,
  GET_BRANDS_ADMIN,
  GET_PLAN,
  FETCH_SUCCESS,
} from '../../shared/constants/ActionTypes';
import {db, secauth} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const getbrands = (user) => {
  return (dispatch) => {
    var docRef = db.collection('brands').where('created_by', '==', user?.uid);
    // .orderBy('created_at', 'desc');
    const brandslist = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.key = doc.id;
        brandslist.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_BRANDS, payload: brandslist});
    });
  };
};

export const getbrandadmin = () => {
  return (dispatch) => {
    var docRef = db.collection('brand_admin').orderBy('created_at', 'desc');
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();

        data.key = doc.id;

        adminslist.push(data);
      });
      dispatch({type: GET_BRANDS_ADMIN, payload: adminslist});
    });
  };
};

export function addbrand(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      var docRef = firebase.firestore().collection('brands').doc();
      var storageRef = firebase.storage().ref();
      if (event.brand_logo) {
        var uploadTask = storageRef
          .child('/brands/' + event.brand_logo.file.name + new Date().getTime())
          .put(event.brand_logo.file, metadata);
        console.log(uploadTask);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              event.brand_logo = downloadURL;
              docRef.set({
                auto_id: docRef.id,
                // brand_code: "",
                brand_name: event.brand_name,
                brand_desc: event.brand_desc,
                brand_logo: event.brand_logo,
                brand_address: event.brand_address,
                is_deleted: false,
                is_active: true,
                created_at: firebase.firestore.Timestamp.fromDate(new Date()),
                updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                created_by: firebase.auth().currentUser.uid,
                updated_by: firebase.auth().currentUser.uid,
              });
              resolve('added');
              dispatch({type: FETCH_SUCCESS});
            });
          },
        );
      } else {
        docRef.set({
          auto_id: docRef.id,
          // brand_code: "",
          brand_name: event.brand_name,
          brand_desc: event.brand_desc,
          brand_logo: event?.brand_logo ?? '',
          brand_email: event.brand_email,
          brand_contactno: event.brand_contactno,
          brand_address: event.brand_address,
          is_deleted: false,
          is_active: true,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        });
        resolve('added');
      }
    });
  };
}

export function createAdmin(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      secauth
        .createUserWithEmailAndPassword(event.Email, event.Mobile.toString())
        .then(function (firebaseUser) {
          console.log(firebaseUser.user.uid);
          console.log(event);
          var docRef = firebase
            .firestore()
            .collection('brand_admin')
            .doc(firebaseUser.user.uid);
          console.log(docRef);
          docRef.set({
            auto_id: docRef.id,
            auth_id: firebaseUser.user.uid,
            brand_admin_code: '',
            brand_admin_email: event.Email.toLowerCase() ?? '',
            brand_admin_firstname: event.FirstName ?? '',
            brand_admin_lastname: event.LastName ?? '',
            brand_admin_mobilenumber: event.Mobile ?? '',
            brand_admin_last_login: '',
            ref_brand_code: event?.Brand ?? '',
            ref_plan_id: event.ref_plan_id ?? '',
            ref_status_id: 1,
            ref_subscription_status_id:
              parseInt(event.ref_subscription_status_id) ?? 1,
            subscription_expiry_date: event.subscription_expiry_date ?? '',
            created_at: firebase.firestore.Timestamp.fromDate(new Date()),
            updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
            created_by: firebase.auth().currentUser.uid,
            updated_by: firebase.auth().currentUser.uid,
            ref_role_id: 2,
          });
          secauth.signOut();
          firebase
            .firestore()
            .collection('brands')
            .where('brand_code', '==', event.Brand)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, ' => ', doc.data());
                firebase
                  .firestore()
                  .collection('brands')
                  .doc(doc.id)
                  .update({
                    is_active: false,
                  })
                  .then(() => {
                    console.log('Document successfully updated!');
                    dispatch({type: FETCH_SUCCESS});
                    resolve(event);
                  });
              });
            })
            .catch((error) => {
              console.log('Error getting documents: ', error);
              reject(error.message);
            });
          resolve(event);
        })

        .catch(function (error) {
          console.log(error.message);
          reject(error.message);
        });
    });
  };
}

export function addPlan(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('md_brand_plan').doc();
      docRef
        .set({
          id: docRef.id,
          auto_id: docRef.id,
          ref_plan_id: docRef.id,
          name: event.PlanName ?? '',
          tag: event.plantype ?? '',
          tagColor: '#11C15B',
          priceColor: '#0A8FDC',
          title: event.plantype ?? '',
          price: event.PlanPrice ?? 0,
          plan_last_login: '',
          max_channel_count: event.MaximumChannelcount ?? '',
          plan_subscription_expiry_date:
            event.plan_subscription_expiry_date ?? '',
          is_active: '1',
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
          pricingList: [
            {
              id: 1,
              title: 'All features from previous plan',
            },
            {
              id: 2,
              title: 'Memberships and bundles',
            },
            {
              id: 3,
              title: 'Advanced quizzes',
            },
            {
              id: 4,
              title: 'Private & hidden courses',
            },
            {
              id: 5,
              title: '2 Site admin accounts',
            },
            {
              id: 6,
              title: '5 Course admins/authors',
            },
          ],
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function UpdatePlan(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('md_brand_plan')
        .doc(event.id);
      docRef
        .update({
          name: event.PlanName ?? '',
          tag: event.plantype ?? '',
          title: event.plantype ?? '',
          price: event.PlanPrice ?? 0,
          max_channel_count: event.MaximumChannelcount ?? '',
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export const getplan = () => {
  return (dispatch) => {
    var docRef = db.collection('md_brand_plan').orderBy('created_at', 'desc');
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        adminslist.push(data);
      });
      dispatch({type: GET_PLAN, payload: adminslist});
    });
  };
};

export const updatebrandadmin = (event, admin) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = db.collection('brand_admin').doc(admin);
      docRef
        .update({
          brand_admin_email: event.Email ?? '',
          brand_admin_firstname: event.FirstName ?? '',
          brand_admin_lastname: event.LastName ?? '',
          brand_admin_mobilenumber: event.Mobile ?? '',
          ref_brand_code: event?.Brand ?? '',
          ref_status_id: event.ref_status_id,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then((res) => {
          console.log('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
          resolve(res);
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
};

export function updatebrand(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      console.log(event);
      var docRef = firebase.firestore().collection('brands').doc(event.id);
      var storageRef = firebase.storage().ref();
      if (event.isnewfile) {
        var uploadTask = storageRef
          .child('/brands/' + event.brand_logo.file.name + new Date().getTime())
          .put(event.brand_logo.file, metadata);
        // console.log(uploadTask)
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              event.brand_logo = downloadURL;
              docRef.update({
                brand_name: event.brand_name,
                brand_desc: event.brand_desc,
                brand_logo: event.brand_logo,
                brand_email: event.brand_email,
                brand_contactno: event.brand_contactno,
                brand_address: event.brand_address,
                updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                updated_by: firebase.auth().currentUser.uid,
              });
              resolve('updated');
              dispatch({type: FETCH_SUCCESS});
            });
          },
        );
      } else {
        docRef.update({
          brand_name: event.brand_name,
          brand_desc: event.brand_desc,
          brand_email: event.brand_email,
          brand_contactno: event.brand_contactno,
          brand_address: event.brand_address,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        });
        resolve('updated');
        dispatch({type: FETCH_SUCCESS});
      }
    });
  };
}

export function onDeleteBrandAdmin(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);

      var docRef = firebase.firestore().collection('brand_admin').doc(event);
      // Set the "capital" field of the city 'DC'
      docRef
        .update({
          ref_status_id: 0,
        })
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}
