import React from 'react';
import {BiUser, BiUserCircle, BiStoreAlt, BiCategory} from 'react-icons/bi';

const routesConfig = [
  {
    id: 'app',
    title: 'Menu',
    messageId: 'sidebar.sample',
    type: 'group',
    children: [
      {
        id: 'page-1',
        title: 'User',
        messageId: 'sidebar.sample.page1',
        type: 'item',
        icon: <BiUser />,
        path: '/sample/page-1',
      },
      {
        id: 'page-2',
        title: 'Page 2',
        messageId: 'sidebar.sample.page2',
        type: 'item',
        icon: <BiUserCircle />,
        path: '/sample/page-2',
      },
      {
        id: 'Category',
        title: 'Category',
        messageId: 'sidebar.sample.Category',
        type: 'item',
        icon: <BiCategory />,
        path: '/sample/Category',
      },
      {
        id: 'SubCategory',
        title: 'SubCategory',
        messageId: 'sidebar.sample.SubCategory',
        type: 'item',
        icon: <BiCategory />,
        path: '/sample/SubCategory',
      },
      {
        id: 'plan',
        title: 'Plan',
        messageId: 'sidebar.sample.plan',
        type: 'item',
        icon: <BiStoreAlt />,
        path: '/sample/plan',
      },
    ],
  },
];
export default routesConfig;
