import {
  GET_BRANDS,
  GET_BRANDS_ADMIN,
  GET_PLAN,
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  brands: [],
  adminuser: [],
  plan: [],
  categories: [],
  subcategory: [],
};

const DataReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case GET_BRANDS_ADMIN:
      return {
        ...state,
        adminuser: action.payload,
      };
    case GET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_SUBCATEGORIES:
      return {
        ...state,
        subcategory: action.payload,
      };
    default:
      return state;
  }
};

export default DataReducer;
